import {
  NewpayBasketErrorPage,
  NewpayErrorPage,
  ZipBasketErrorPage,
  ZipErrorPage,
  Application as FormBuilderApp,
  FormBuilder as Form,
  selectTheme,
  ThemeVariants,
} from 'components-typescript-react';
import React, { useMemo, useRef } from 'react';

import { CAC, InitToken, ProductNames } from '@app-types';
import { CheckoutAuthCertificate } from '@utils';
import { CheckoutAuthCertificateLens } from '@utils/CheckoutAuthCertificate/types';
import { BasketErrors, CACError, DekoWalletCheckoutError } from '@utils/Errors/types';

import { ThemeProvider } from 'styled-components';
import GenericErrorPage from './GenericErrorPage';

const defaultErrorSchema = {
  $schema: 'https://json-schema.org/draft/2019-09/schema',
  title: 'We are unable to process your request at this time',
  description: 'Error',
  _page: 'ErrorPage',
  meta: {
    buttonText: 'Back to retailer',
    genericError: true,
  },
  properties: {
    displayMessage: {
      value:
        "Unfortunately, we are having trouble processing your application. This can happen from time to time, if an application contains incorrect or incomplete information. \n\nIf you wish to try again, please return to the retailer's website and resubmit your application.",
      readOnly: true,
    },
    infoMessage: {
      value: "You can return to the retailer's website by clicking on the button below.",
      readOnly: true,
    },
  },
};

const sessionExpiredSchema = {
  ...defaultErrorSchema,
  title: 'Sorry, your session has expired',
  properties: {
    ...defaultErrorSchema.properties,
    displayMessage: {
      ...defaultErrorSchema.properties.displayMessage,
      value: "We're sorry, your  session has expired.\n\nTo start a new session please return to the merchant's site.",
    },
  },
};

interface ErrorPageProps {
  token: InitToken | CAC;
  onClose: () => void | Promise<void>;
  error?: DekoWalletCheckoutError;
  isSessionExpired?: boolean;
}

const ErrorPage: React.FC<ErrorPageProps> = ({
  token,
  onClose,
  isSessionExpired = false,
  error = [
    {
      product: ProductNames.GENERIC,
      errors: [
        {
          error: CACError.generic,
          info: {
            message: 'something went wrong!',
          },
        },
      ],
    },
  ],
}) => {
  const errorRef = useRef<Error>();
  const tokenAccess: CheckoutAuthCertificateLens | undefined = useMemo(() => {
    try {
      return CheckoutAuthCertificate(token);
    } catch (e) {
      errorRef.current = e;
      return undefined;
    }
  }, [token]);

  const showDefaultError = (schema: any) => (
    <ThemeProvider theme={selectTheme(ThemeVariants.bnpp)}>
      <FormBuilderApp schema={schema} onClose={onClose} FormBuilder={Form.FormBuilder} />
    </ThemeProvider>
  );

  if (errorRef.current) {
    return <GenericErrorPage heading="An error has occurred" body="No available products for merchant" />;
  }

  const { availableProductNames = [], ineligibleProducts = [] } = tokenAccess || {};
  const [firstError] = error;

  if (ineligibleProducts.length === 0) {
    return availableProductNames[0] === ProductNames.REVOLVING_CREDIT ? (
      <NewpayErrorPage onClose={onClose} />
    ) : (
      showDefaultError(isSessionExpired ? sessionExpiredSchema : defaultErrorSchema)
    );
  }

  const [firstIneligbleProduct] = ineligibleProducts;
  const [firstProductError] = firstIneligbleProduct.errors;

  if (firstIneligbleProduct.product === ProductNames.REVOLVING_CREDIT) {
    return (
      <>
        {firstProductError.error === CACError.amountTooHigh && (
          <NewpayBasketErrorPage
            error={BasketErrors.valueExceedsCreditLimit}
            onClose={onClose}
            basketValue={tokenAccess.basketValue}
            creditLimit={Number(firstProductError.info.maxAmount)}
          />
        )}

        {firstProductError.error === CACError.amountTooLow && (
          <NewpayBasketErrorPage
            error={BasketErrors.valueTooLow}
            onClose={onClose}
            basketValue={tokenAccess.basketValue}
            creditMinimumValue={Number(firstProductError.info.minAmount)}
          />
        )}

        {firstProductError.error === CACError.basketItemNotSupported && <NewpayErrorPage onClose={onClose} />}

        {[CACError.amountTooHigh, CACError.amountTooLow, CACError.basketItemNotSupported].indexOf(
          firstProductError.error
        ) === -1 && <NewpayErrorPage onClose={onClose} />}
      </>
    );
  }

  if (firstIneligbleProduct.product === ProductNames.SPLIT) {
    return (
      <>
        {CACError.amountTooLow === firstProductError.error && (
          <ZipBasketErrorPage
            minimumBasket={firstProductError.info.minAmount}
            onClose={onClose}
            basketValue={tokenAccess.basketValue}
          />
        )}
        {CACError.amountTooHigh === firstProductError.error && (
          <ZipBasketErrorPage
            maximumBasket={firstProductError.info.maxAmount}
            onClose={onClose}
            basketValue={tokenAccess.basketValue}
          />
        )}
        {[CACError.amountTooLow, CACError.amountTooHigh].indexOf(firstProductError.error) === -1 && (
          <ZipErrorPage onClose={onClose} />
        )}
      </>
    );
  }

  if (availableProductNames.length > 0) {
    if (availableProductNames[0] === ProductNames.SPLIT) {
      return <ZipErrorPage onClose={onClose} />;
    }

    return <NewpayErrorPage onClose={onClose} />;
  }

  if (firstError && firstError.product === ProductNames.GENERIC) {
    if (firstError.errors[0].error === CACError.generic) {
      return <GenericErrorPage heading="An error has occurred" body={firstError.errors[0].info.message} />;
    }
  }

  return showDefaultError(isSessionExpired ? sessionExpiredSchema : defaultErrorSchema);
};

export default ErrorPage;
