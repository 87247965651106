import { defaultTileState, Services, TileState } from 'components-typescript-react';
import React, { useCallback, useRef, useState } from 'react';

import { LenderName, ProductNames } from '@app-types';

import { checkEligibilityResult, updateTileStateWithCreditInfo } from './cookie-eligibility';
import { getServiceFromProduct, resolveServiceProductInfoFromLender } from './helper';
import { EligibilityCheckResult, UseTileProps, UseTileResult } from './types';

export const useTile = (
  {
    availableProductsFromCert,
    firstAvailableProduct,
    isStandAlone,
    hasCheckoutToken,
    isAppVisible,
    isWalletOpen,
    parkPosition,
  }: UseTileProps,
  currentTileState: TileState = defaultTileState
): UseTileResult => {
  const [eligibilityCheckResult, setEligibilityCheckResult] = React.useState<EligibilityCheckResult>(() =>
    checkEligibilityResult(firstAvailableProduct)
  );

  const [targetProduct, setTargetProduct] = useState<ProductNames>(
    eligibilityCheckResult.eligibleServiceProductInfo?.product ?? firstAvailableProduct
  );
  const prevIsWalletOpenRef = useRef<boolean>(isWalletOpen);
  const [activeService, setActiveService] = React.useState<Services | undefined>(undefined);

  const [isTileMinimised, setIsTileMinimised] = React.useState<boolean>(currentTileState.isMinimised);
  const [lowestMerchantApr, setLowestMerchantApr] = React.useState<number | undefined>(
    currentTileState.lowestMerchantApr
  );
  const [lowestEligibleApr, setLowestEligibleApr] = React.useState<number | undefined>(
    currentTileState.lowestEligibleApr
  );

  const hasMultiLender = availableProductsFromCert.includes(ProductNames.MULTI_LENDER); // TBD

  const [allowTileVisible, setAllowTileVisible] = React.useState<boolean>(
    !isStandAlone && !hasCheckoutToken && !hasMultiLender && !isWalletOpen
  );

  const service: Services = React.useMemo(() => {
    if (eligibilityCheckResult.eligibleServiceProductInfo?.service) {
      return eligibilityCheckResult.eligibleServiceProductInfo.service;
    }
    return activeService ?? getServiceFromProduct(targetProduct);
  }, [activeService, eligibilityCheckResult.eligibleServiceProductInfo?.service, targetProduct]);

  // console.log(`Check Service - Cookies: ${availableProductsFromCert[0]}, FirstAvailable: ${firstAvailableProduct}, TargetProduct ${targetProduct} Preferred Service: ${activeService}`);
  // console.log(`Check Visibility - Standalone: ${isStandAlone}, CheckoutToken: ${hasCheckoutToken}, Multilender: ${hasMultiLender}, AllowTileVisible: ${allowTileVisible} AppVisiable: ${isAppVisible}`);

  const updateTileOnActiveLenderChange = useCallback(
    (lender: LenderName) => {
      const serviceProductInfo = resolveServiceProductInfoFromLender(targetProduct, lender);
      if (serviceProductInfo?.service) {
        setActiveService(serviceProductInfo.service);
        if (serviceProductInfo.product) {
          setTargetProduct(serviceProductInfo.product);
        }
      }
    },
    [targetProduct]
  );

  React.useEffect(() => {
    if (isWalletOpen !== prevIsWalletOpenRef.current) {
      prevIsWalletOpenRef.current = isWalletOpen;
      if (isWalletOpen) {
        if (!isTileMinimised) setIsTileMinimised(true);
      } else if (targetProduct === ProductNames.BLACKHORSE_FLEXPAY || targetProduct === ProductNames.NEWPAY) {
        setLowestEligibleApr(0);
        setLowestMerchantApr(0);
        setEligibilityCheckResult(checkEligibilityResult(targetProduct));
      }
    }
  }, [isWalletOpen, isTileMinimised, targetProduct]);

  React.useEffect(() => {
    setAllowTileVisible(!isStandAlone && !hasCheckoutToken && !hasMultiLender && !isWalletOpen);
  }, [hasCheckoutToken, isStandAlone, isAppVisible, hasMultiLender, isWalletOpen]);

  React.useEffect(() => {
    if (lowestMerchantApr !== currentTileState.lowestMerchantApr) {
      setLowestMerchantApr(currentTileState.lowestMerchantApr);
    }

    if (lowestEligibleApr !== currentTileState.lowestEligibleApr) {
      setLowestEligibleApr(currentTileState.lowestEligibleApr);
    }
  }, [currentTileState.lowestMerchantApr, currentTileState.lowestEligibleApr, lowestEligibleApr, lowestMerchantApr]);

  const partialTileState: Partial<TileState> = updateTileStateWithCreditInfo(
    {
      isVisible: isAppVisible && allowTileVisible,
      lowestMerchantApr,
      lowestEligibleApr,
      isMinimised: isTileMinimised,
      service,
      parkPosition,
    },
    eligibilityCheckResult.eligibilityHistory
  );

  return {
    partialTileState,
    updateTileOnActiveLenderChange,
    setLowestMerchantApr,
    setLowestEligibleApr,
    targetProduct,
  };
};
