import Cookies from 'js-cookie';

import { LenderName, ProductNames } from '@app-types';
import { CookieType } from '@utils/eligibilityBeaconPersistence/types';

const handleCookieUIs = (productName: ProductNames, lender: LenderName) => {
  let showCookieConsent = true;
  let showCookieNoticeBanner = false;

  if (productName === ProductNames.BLACKHORSE_FLEXPAY) {
    showCookieConsent = false;
    if (lender === LenderName.BLACKHORSE) {
      showCookieNoticeBanner = !Cookies.get(CookieType.BlackHorseCookieConsent);
    }
  } else if (lender === LenderName.BLACKHORSE) {
    showCookieConsent = false;
    showCookieNoticeBanner = !Cookies.get(CookieType.BlackHorseCookieConsent);
  } else if (lender === LenderName.NEWPAY) {
    showCookieConsent = false;
    if (productName === ProductNames.REVOLVING_CREDIT) {
      showCookieNoticeBanner = !Cookies.get(CookieType.NewPayCookieConsent);
    }
  }

  return {
    showCookieConsent,
    showCookieNoticeBanner,
  };
};

export default handleCookieUIs;
