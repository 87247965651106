import { Services } from 'components-typescript-react';

import { LenderName, ProductNames } from '@app-types';

import { ResolvedServiceProductInfo } from './types';

export const getServiceFromProduct = (product: ProductNames): Services => {
  switch (product) {
    case ProductNames.SPLIT:
      return Services.zip;
    case ProductNames.REVOLVING_CREDIT:
      return Services.newPay;
    case ProductNames.NEWPAY:
      return Services.blackhorseNewpay;
    case ProductNames.BLACKHORSE_FLEXPAY:
      return Services.blackhorseFlexpay;
    case ProductNames.INSTALMENT:
    case ProductNames.DEKO_MONTHLY:
    case ProductNames.MULTI_LENDER:
      return Services.instalment;
    case ProductNames.GENERIC:
    default:
      return Services.retailFinance;
  }
};

export const resolveServiceProductInfoFromLender = (
  originalProduct: ProductNames,
  lender: LenderName | string
): ResolvedServiceProductInfo | undefined => {
  switch (lender) {
    case LenderName.BLACKHORSE:
      return {
        service: Services.blackhorseFlexpay,
        product: originalProduct,
      };
    case LenderName.NEWPAY: {
      const blackhorseFlexpayProducts = [ProductNames.BLACKHORSE_FLEXPAY, ProductNames.NEWPAY];
      if (blackhorseFlexpayProducts.includes(originalProduct)) {
        return {
          service: Services.blackhorseNewpay,
          product: ProductNames.NEWPAY,
        };
      }

      if (originalProduct === ProductNames.REVOLVING_CREDIT) {
        return {
          service: Services.newPay,
          product: originalProduct,
        };
      }
      return undefined;
    }
    default:
      return undefined;
  }
};

export function isNumberInteger(n: number) {
  return n % 1 === 0;
}
