import { FontFaces } from 'components-typescript-react';
import React, { useCallback, useEffect } from 'react';

import { CommandTypes, LenderName, WalletProps } from '@app-types';
import WorkflowStep from '@components/WorkflowStep';
import { INSTALMENT_BASE_URL } from '@components/WorkflowStep/productInfo';
import { AppPropManager } from '@utils';

import { useTile } from '@components/Tile/useTile';
import { PluginContainer, WalletOverlay, WalletWrapper } from './styles';

const fonts = FontFaces.map((fontFace) => ({
  ...fontFace,
  src: fontFace.src.map((source) => ({
    ...source,
    url: `${INSTALMENT_BASE_URL}${source.url?.replace(/^\//, '')}`,
  })),
}));

const Wallet: React.FC<WalletProps> = ({
  tileRef,
  authToken,
  authCertificate,
  initToken,
  initCertificate,
  isOpen,
  isVisible,
  tileParkPosition,
  promotionalValue,
  onApplicationEvent,
  onEmit,
  error,
  firstAvailableProduct,
  onChangeActiveLender,
  isStandaloneUI,
}) => {
  const { isStandAlone } = initCertificate;
  const { partialTileState, updateTileOnActiveLenderChange, setLowestMerchantApr, setLowestEligibleApr } = useTile(
    {
      availableProductsFromCert: initCertificate.availableProductNames,
      firstAvailableProduct,
      isStandAlone,
      hasCheckoutToken: AppPropManager.isCheckoutToken,
      isWalletOpen: isOpen,
      isAppVisible: isVisible,
      parkPosition: tileParkPosition,
    },
    tileRef.current?.queryState()
  );
  const onSoftClose = (): void => onEmit({ type: CommandTypes.soft_close });
  const onClose = (): void => !isStandAlone && onEmit({ type: CommandTypes.close });
  const openWallet = (): void => onEmit({ type: CommandTypes.open });
  const clearPromotional = (): void => onEmit({ type: CommandTypes.clearPromotional });

  const handleChangeActiveLender = (lender: LenderName) => {
    // console.log(`Change Active Lender: ${lender} with product ${firstAvailableProduct}`);
    updateTileOnActiveLenderChange(lender);
    onChangeActiveLender(lender);
  };

  const onMessage = useCallback(
    (evt: MessageEvent) => {
      switch (evt.data?.message) {
        case 'LOWEST_MERCHANT_OFFER':
          setLowestMerchantApr(evt.data?.lowestApr);
          break;
        case 'LOWEST_ELIGIBLE_OFFER':
          setLowestEligibleApr(evt.data?.lowestApr);
          break;
        default:
      }
    },
    [setLowestMerchantApr, setLowestEligibleApr]
  );

  React.useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [onMessage]);

  useEffect(() => {
    if (!tileRef.current) return;

    // console.log(`Tile State to update: ${JSON.stringify(partialTileState)}`);
    const currentTileState = tileRef.current.queryState();

    const newTileState = { ...currentTileState, ...partialTileState };
    if (newTileState !== currentTileState) {
      tileRef.current.updateState(newTileState);
    }
  }, [partialTileState]);

  useEffect(() => {
    if (isStandAlone) {
      openWallet();
    }
  }, [isStandAlone]);

  return (
    <>
      {!isStandAlone && isVisible && (
        <WalletOverlay isOpen={isOpen} data-testid="wallet-overlay" onClick={onSoftClose} />
      )}
      <PluginContainer isStandaloneUI={isStandaloneUI} isOpen={isOpen}>
        <WalletWrapper isVisible={isVisible} isOpen={isOpen} fonts={fonts}>
          <WorkflowStep
            authToken={authToken}
            authCertificate={authCertificate}
            initToken={initToken}
            initCertificate={initCertificate}
            promotional={promotionalValue}
            error={error}
            onApplicationEvent={onApplicationEvent}
            onClose={onClose}
            onChangeActiveLender={handleChangeActiveLender}
            clearPromotional={clearPromotional}
          />
        </WalletWrapper>
      </PluginContainer>
    </>
  );
};

export default Wallet;
