import { InitOptions, JWTLoader } from '@app-types';
import { TileParkPositions } from 'components-typescript-react';
import WalletApi from '@components/WalletApi';

let initiated = false;

export default function init(initToken: JWTLoader, options?: InitOptions): WalletApi {
  if (initiated) throw new Error('Wallet already initialised');

  initiated = true;

  return new WalletApi({
    isVisible: true,
    tileParkPosition: TileParkPositions.BOTTOM_RIGHT,
    ...options,
    initToken,
  });
}
